<template>
  <!-- 行驶数据 -->
  <div ref="container" class="chart" :class="{
    [$style.chart]: true,
    [$style.large]: large
  }"></div>
</template>

<style module lang="scss">
.chart {
  height: 6rem;

  &.large {
    height: 15rem;
  }
}
</style>

<script>
import { Chart } from "@antv/g2";

const { remUnit = 20 } = window;

export default {
  props: {
    title: {
      type: String,
    },
    large: {
      type: Boolean,
      default: false
    },
    vData: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      chart: undefined,
      annotation: undefined,
    };
  },
  mounted() {
    const { vData } = this;

    this.initChart(vData);
  },
  watch: {
    vData(val) {
      this.initChart(val);
    }
  },
  methods: {
    initChart(data) {
      const { container } = this.$refs;
      const { large, annotation } = this;
      let { chart } = this;
      let _annotation;

      if (!data || data.length === 0) return;

      if (chart) {
        const { option } = annotation;
        _annotation = annotation;
        // 添加文本标注
        data.forEach((item) => {
          const o = option.find((opt) => {
            if (opt.position[0] === item.label) {
              opt.position[1] = item.value;
              opt.content = item.value;

              return true;
            }

            return false;
          })

          if (!o) {
            _annotation = chart
              .annotation()
              .text({
                position: [item.label, item.value],
                content: item.value || '',
                style: {
                  fontSize:  (large ? 0.8 : 0.7) * remUnit,
                  textAlign: 'center',
                  fill: '#ffffff'
                },
                // animate: false,
                offsetY: -0.5 * remUnit,
              })
          }
        });

        option.filter((opt) => {
          return data.some((item) => opt.position[0] === item.label);
        });
        chart.changeData(data);

        this.annotation = _annotation;

        return;
      }
      
      chart = new Chart({
        container,
        autoFit: true,
        padding: [(large ? 2 : 1) * remUnit, remUnit, 1.5 * remUnit, 1.5 * remUnit],
      });

      chart.scale('value', {
        nice: true,
      });

      chart.tooltip(false);
      chart.interaction('active-region');

      chart
        .interval()
        .position('label*value')
        .size(remUnit)
        .style({ radius: [remUnit, remUnit, 0, 0], fill: "l(90) 0:#F15887 1:#FE9B86" });

      chart.axis("label", {
        grid: {
          line: {
            style: {
              stroke: "#343B74",
            },
          },
        },
        line: {
          style: {
            stroke: "#343B74",
          },
        },
        tickLine: null,
        label: {
          style: {
            fontSize: (large ? 0.7 : 0.6) * remUnit,
            fill: "#5961A6",
          },
        },
      });
      chart.axis("value", {
        grid: null,
        label: {
          style: {
            fontSize: (large ? 0.7 : 0.6) * remUnit,
            fill: "#5961A6",
          },
        },
      });

      // 添加文本标注
      data.forEach((item) => {
        _annotation = chart
          .annotation()
          .text({
            position: [item.label, item.value],
            content: item.value || '',
            style: {
              fontSize:  (large ? 0.8 : 0.7) * remUnit,
              textAlign: 'center',
              fill: '#ffffff'
            },
            // animate: false,
            offsetY: -0.5 * remUnit,
          })
      });

      chart.data(data);
      chart.render();
      this.annotation = _annotation;
      this.chart = chart;
    },
  },
};
</script>